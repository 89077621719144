import React from 'react'
import Navbar from './Navbar'
import { Link } from 'react-router-dom'

function Hero() {
    return (
        <div className='bg-[url("https://res.cloudinary.com/ddlupbcws/image/upload/q_auto/v1695761335/Rectangle_1417_dhxncf.webp")] lg:pb-[200px] bg-[length:100%_100%] bg-no-repeat'>
            <div>
                <Navbar />
                <div className='flex flex-col mt-[10.37rem] lg:ml-[5.69rem] lg:pb-0 pb-24 lg:w-[46.375rem] px-[1rem]'>
                    <h1 className='font-plus lg:text-[4.375rem] text-center lg:text-left text-[2.8125rem] text-white font-extrabold'>
                        Introducing Hamma Trading Competition
                    </h1>
                    <p className='font-plus text-[1rem] text-white font-medium text-center lg:text-left mt-[1.5rem]'>Join the October 2024 Competition, climb up the leaderboard, and win up to N1,000,000 in cash!</p>
                    <div className='flex flex-col lg:flex-row gap-[0.86rem] items-center'>
                        <a href='#leader'>
                            <button className='font-plus whitespace-nowrap text-[1rem] text-black font-extrabold lg:mx-0 mx-auto rounded-[0.625rem] lg:w-[15.06rem] bg-white lg:px-[1.75rem] py-[1.25rem] px-[0.5rem] mt-[2.5rem]'>
                                View Leaderboard
                            </button>
                        </a>
                        <Link to="/login" className='font-plus flex justify-center whitespace-nowrap text-[1rem] text-black font-extrabold lg:mx-0 mx-auto rounded-[0.625rem] w-[15.06rem] bg-[#06FD06] lg:px-[1.75rem] py-[1.25rem] mt-[2.5rem]'>
                            <button>
                                Join Competition
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero